(function(){

	var module = 'products';
	var $body = $('html, body');

	setCategory();
	getCategory();

	function setCategory(){
		var $items = $('.bgrid');
		var $item = $items.find('a');
		$item.on('click', $items, function(event){
			var category = $(this).attr('data-category'); 
			window.sessionStorage.setItem('category', category);
		});
	};

	function getCategory(){
		var $breadcrumb = $('.breadcrumb');
		var $item_active = $breadcrumb.find('li.active');
		var category_get = window.sessionStorage.getItem('category');
		$item_active.html(category_get);
	};

})();